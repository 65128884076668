.filterButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin-left: 16px;
    button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px 8px 16px;
        gap: 4px;
        background: black;
        color: white;
        border: 1px solid #e4e3db;
        border-radius: 24px;
        width: 155px;
        svg {
            width: 14px;
        }
    }
}

.filterButtonContainerWhite {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin-left: 16px;
    button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px 8px 16px;
        gap: 4px;
        background: #f5f4ec;
        border: 1px solid #e4e3db;
        border-radius: 24px;
        width: 155px;
        svg {
            width: 14px;
        }
    }
}
