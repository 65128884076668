$mobile: 675px;

.item {
    cursor: pointer;
    user-select: none;

    height: 36px;
    font-size: 1em;

    position: relative;

    svg {
        width: 0.75em;
    }

    &.highlighted {
        background: #f4f4f4;
    }
}

.button {
    outline: none;
    border: none;
    background: none;
    text-align: center;
    width: 44px;
    height: 42px;
    flex-shrink: 0;
    padding: 0 17px;

    &:disabled {
        color: #ddd;
    }

    &:not(:disabled):focus,
    &:not(:disabled):hover {
        background: #f0f0f0;
        cursor: pointer;
    }

    svg {
        height: 20px;
        position: relative;
        top: 1px;
    }
}

.tick {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 2px;
    text-align: center;
    flex-shrink: 0;

    /* stylelint-disable-next-line */
    transition: background 200ms;

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }

    /* stylelint-disable-next-line */
    svg {
        opacity: 0;
        height: 16px;
        margin-left: 3px;
        color: white;
        display: block;
    }

    border: 1px solid #ccc;
    background: none;

    &.selected {
        border: 1px solid black;
        background: black;

        svg {
            opacity: 1;
        }
    }
}

/* facetFilter */

.facetFilter {
    background: #f5f4ec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: 'relative';
    margin-top: 20px;
    padding: 8px 16px 16px;
    gap: 8px;
    border-radius: 4px;

    /* stylelint-disable-next-line a11y/no-display-none */
    @media (max-width: $mobile) {
        display: none;
    }
}

.loading {
    filter: grayscale(100%);
    opacity: 0.5;
    pointer-events: none;
}

.dropdownsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
}

.wrapper {
    position: relative;
}

.label {
    display: inline-block;
    font-size: 1em;
    margin-bottom: 8px;
}

.box {
    display: flex;
    border: 1px solid black;
    align-items: center;
    height: 44px;
    line-height: 22px;
    padding: 16px 11px;
    padding-right: 0;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 1.2em;
    background: white;

    &[aria-disabled='true'] {
        border-color: #999;
    }

    &.focused {
        border-color: #03c;
        box-shadow: 0 0 0 1px #03c;
    }
}

.chips {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 8px;
}

.clearAllButton {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: #2653d9;
    font: inherit;
    line-height: normal;
    cursor: pointer;
    outline: none;
}

.input {
    color: rgba(0, 0, 0, 0.54);
    outline: none;
    border: none;
    font: inherit;
    padding: 0.5rem;
    display: block;
    width: 100%;
    cursor: pointer;

    &::placeholder {
        font: inherit;
        color: #757575;
        opacity: 1;
        text-overflow: ellipsis;
    }

    &:disabled::placeholder {
        color: #999;
    }
}

.value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1em;
    flex-grow: 1;
    height: 20px;
    padding: 0;
    line-height: 20px;
}

.count {
    color: #666;
    font-weight: normal;
    padding-right: 11px;
    margin-left: 6px;
    text-align: right;
}

.size {
    position: absolute;
    right: 0;
    top: 4px;
    bottom: 4px;
    z-index: 0;
    background: #f2f2f2;

    .highlighted & {
        background: #dfdfdf;
    }
}

.itemContent {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-size: 1em;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    box-sizing: border-box;
    white-space: nowrap;
}

.list {
    border: 1px solid black;
    border-top: 2px solid #03c;
    height: auto;
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 8px 0;
    box-shadow: 0 5px 10px rgba(100, 100, 100, 0.4);
    z-index: 500;
    background: white;
    border-radius: 0 0 2px 2px;
    position: absolute;
    top: 68px;
    left: -1px;
    right: -1px;

    h2 {
        font-size: 1em;
        padding: 0.5rem 0;
        margin: 0;
    }
}
