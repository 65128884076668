$mobile: 675px;

.searchBarContainer {
    display: flex;
    padding: 8px;
    gap: 20px;
    background: white;
    border: 1px solid black;
    border-radius: 2px;
    form {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border: none;
        align-items: center;
    }
    label {
        width: 100%;
    }
    input {
        width: 100%;
        font-size: 1rem;
        border: none;
    }
    button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;

        svg {
            width: 16px;
            height: 16px;
        }

        @media (max-width: $mobile) {
            padding: 10px;
        }

        &.clearBtn {
            padding-right: 12px;
            margin-right: 6px;
            border-right: 1px solid #e4e3db;

            @media (max-width: $mobile) {
                margin-right: 2px;
                height: 12px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        &.clearBtn svg {
            color: black;
            scale: 1.4;
        }
    }

    .formActionButtons {
        display: flex;
        flex-direction: row-reverse;
    }

    @media (max-width: $mobile) {
        padding: 1px 1px 1px 16px;

        border: 1px solid #e4e3db;
        border-radius: 36px;
        form {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        button {
            padding: 10px;
        }
        input {
            width: 85%;
            border: none;
        }
    }
    /* stylelint-disable-next-line */
    input:focus {
        outline: none;
    }
}

.loading {
    filter: grayscale(100%);
    opacity: 0.5;
    pointer-events: none;
}
