.showMoreInfoContainer {
    display: flex;
    padding: 8px 0;
    border: none;
    background: none;
    color: #6c6b68;
    cursor: pointer;
    line-height: 14px;
    font-size: 12px;
    &:hover,
    &:focus {
        svg {
            color: #6c6b68;
        }
    }
}

.moreInfo {
    margin-bottom: -5px;
    margin-left: 5px;
    background: white;
    color: #868379;
    width: 10px;
    flex-shrink: 0;
}
