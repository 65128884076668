.button {
    flex-shrink: 0;

    border-radius: 24px;
    font-family: inherit;
    font-size: 12px;

    border: none;
    background: black;
    color: white;

    display: inline-flex;
    align-items: center;
    padding: 0;

    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        background: #aaa;
        color: #666;
    }
}

.label {
    padding-left: 12px;
    padding-right: 8px;
}

.icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
    display: inline-block;
    line-height: 24px;
    vertical-align: center;

    button:not(:disabled):hover & {
        background: #666;
    }

    button:not(:disabled):focus & {
        background: #666;
    }

    svg {
        width: 10px;
        position: relative;
        top: 2px;
        color: inherit;
    }
}
