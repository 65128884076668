/* stylelint-disable a11y/no-display-none */
$collapse: 675px;

.root {
    opacity: 1;
    font-size: 0.89rem;
    line-height: 1;
    margin-top: 16px;
}

.header {
    width: 100%;
    display: flex;

    @media (max-width: 900px) {
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    @media (max-width: $collapse) {
        justify-content: center;
        margin-bottom: 20px;
    }
}

.fill {
    flex-grow: 1;

    @media (max-width: 900px) {
        display: none;
    }
}

.filters {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    @media (max-width: $collapse) {
        display: none;
    }
}

.divider {
    border-right: 1px solid #e5e5e5;
    height: 80%;
}

.twoMobile {
    display: none;

    @media (max-width: $collapse) {
        display: flex;
        flex-wrap: wrap;
        min-width: 100%;
        justify-content: flex-start;
        gap: 26px;

        .SortAndView {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .pager {
            width: 100%;
        }

        .pager div:first-child {
            gap: 6px;
            width: 300px;
        }

        .mobileSortContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid gray;
            border-radius: 20px;
            font-size: 1em;
            height: 22px;
            width: 20%;
        }
    }
}

.sortRow {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 96px 2fr 1fr 1fr 0.7fr 0.3fr 0.5fr 0.5fr;
    align-items: center;
    border-bottom: 1px solid #eee;
    height: 20px;

    span {
        cursor: pointer;
        color: #2653d9;
    }

    svg {
        width: 10px;
    }

    @media (max-width: $collapse) {
        display: none;
    }
}

.noSearchResults {
    height: 250px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addLabelReleaseButton {
    margin: 40px 0;
    cursor: pointer;
    width: 8rem;
    display: flex;
    justify-content: center;
    height: 30px;
    border: 1px solid grey;
    border-radius: 6px;
    background-color: #f2f2f2;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 100%;
        height: 100%;
        color: black;
        text-decoration: none;
    }

    svg {
        width: 16px;
    }
}

.labelReleasesTable {
    display: grid;
}

.labelReleasesTable tr {
    vertical-align: top;
    border-top: 1px solid #e5e5e5;

    &:first-of-type {
        border-top: 0;
    }
}

.showContainer {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px;
    text-align: left;
    margin-left: 16px;

    @media (max-width: $collapse) {
        display: none;
    }
}

.loading {
    filter: grayscale(100%);
    opacity: 0.5;
    pointer-events: none;
}

.spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.versionsLoadingSpinner {
    width: 100vw;
}

.coversOnly {
    tbody {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2em;
        padding: 2em;
    }
}

.sort {
    width: 12px;
    height: 12px;
    margin-left: 2px;
    transition: transform 500ms;
    transform: scaleY(-1);

    &.desc {
        transform: scaleY(1);
    }

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

.catno {
    white-space: nowrap;
    text-align: left;
}

.leftSortParamaters {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
}

.year {
    width: 3rem;
    height: 100%;
}

.skittles {
    .inner {
        display: flex;
        gap: 4px;
    }
}

.actions {
    width: 50px;

    button svg {
        margin: 0;
    }
}

.pagerTop {
    @media (max-width: 900px) {
        display: none;
    }
}

.labelReleasesHeader {
    background: #f5f4ec;
    padding: 8px;
    margin: 8px 0;
    border-radius: 2px;
    display: block;

    @media (max-width: $collapse) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 28px;
        background: #f5f4ec;
        border-radius: 4px;
        padding: 4px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 143%;
    }
}

.progress {
    margin-top: 16px;
}
