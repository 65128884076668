$mobile: 675px;

/* stylelint-disable-next-line a11y/no-display-none */
.mobileSearchbar {
    display: none;

    @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        padding: 0 8px 16px;
        gap: 12px;
        height: 100%;
        background: #f5f4ec;
        border: 1px solid #e4e3db;
        border-radius: 1px 1px 4px 4px;
        svg {
            width: 6.55px;
        }
    }
}

.mobileReleasesPath {
    display: flex;
    align-items: center;
    margin-top: 16px;
    h2 {
        font-weight: bold;
        font-size: 1.2em;
        line-height: 24px;
        color: #31312f;
        margin: 1px;
        padding: 0 8px;
    }
    svg {
        margin-top: 2px;
        margin-left: 10px;
        color: #6c6b68;
        width: 18px;
    }
    button {
        font-size: 1.2em;
        line-height: 24px;
        color: #31312f;
        display: block;
        font-weight: bold;
        border: none;
        background: none;
        cursor: pointer;
    }
}
.stickyContainer {
    @media (max-width: $mobile) {
        height: 100%;
    }
}

.sticky {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 16px 8px 8px;
}

.buttonRow {
    display: flex;
    justify-content: space-between;
    span {
        display: flex;
        gap: 5px;
    }
    svg {
        width: 12px;
    }
}

.mobileFiltersButton {
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px 4px 12px;
    background: #e4e3db;
    border-radius: 24px;
    gap: 10px;
}

.filtersAppliedButton {
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px 4px 12px;
    color: white;
    width: 100px;
    height: 24px;
    background: black;
    border-radius: 24px;
    font-size: 0.9em;
    line-height: 14px;
}

.filterBoxMobile {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    animation-name: slide-up;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    z-index: 1000;
    overflow: auto;
    height: 100vh;

    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }
}

.slideOut {
    animation-name: slide-down;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

.topRow {
    height: 45px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    border-bottom: 1px solid #e4e3db;
    h2 {
        font-weight: 500;
        font-size: 1.2em;
        line-height: 22px;
        text-align: center;
        color: #31312f;
    }
}

.filterSection {
    width: 100%;
    border-bottom: 1px solid #e4e3db;
}

.heading {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 16px;
    color: #31312f;
}

.circleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #2653d9;
}

.circleNumber {
    font-size: 1em;
    font-weight: bold;
    color: white;
}

.close {
    width: 30px;
    margin-left: 10px;
    background: none;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 15px;
    }
}

.clearAll {
    background: none;
    cursor: pointer;
    border: none;
    font-size: 1em;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2653d9;
    width: 70px;
}

.mobileList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    margin-bottom: 16px;
}

.showMore {
    background: none;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 1em;
    line-height: 20px;
    text-decoration-line: underline;
    color: black;
    padding: 8px 8px 16px 12px;
    svg {
        height: 20px;
    }
}

.itemContent {
    display: flex;
    gap: 20px;
    padding: 6px 8px 6px 12px;
}

.tick {
    min-width: 16px;
    height: 16px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
        opacity: 0;
        height: 12px;
        color: white;
        display: block;
    }
    &.selected {
        border: 1px solid black;
        background: #2653d9;
        svg {
            opacity: 1;
            color: #f5f4ec;
        }
    }
}

.value {
    width: 70%;
}

.count {
    font-size: 1em;
    color: #6c6b68;

    background: #f2f2f2;
    text-align: right;
    margin-left: auto;
}
