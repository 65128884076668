.wrapper {
    width: 100%;
    background: none;
    border: none;
    height: 0.4rem;
    border-radius: 2px;
    overflow: hidden;

    position: relative;
}

.bar {
    transform-origin: 0 0;
    height: 100%;
    width: 100%;
    background: #03c;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    transition: transform 80ms;

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }

    .wrapper[aria-valuenow='0'] & {
        transition: none;
    }
}
