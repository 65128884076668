$collapse: 675px;

.release td {
    padding: 1em 0.25em;
    vertical-align: top;
}

.textOnlyRow {
    display: grid;
    grid-template-columns: 4fr 3fr 1fr 0.3fr 0.6fr 0.5fr 0.5fr;
    border-bottom: 1px solid #eee;

    @media (max-width: $collapse) {
        grid-template-columns: 0fr 2fr 0.5fr 0.3fr;
        grid-template-rows: auto 1fr;
        justify-content: center;
        align-items: center;
        background: white;
        border: 1px solid #e4e3db;
        box-shadow: 0 2px 4px rgba(28, 28, 28, 0.05);
        border-radius: 4px;
    }
}

.textWithCoversRow {
    display: grid;
    grid-template-columns: 96px 2fr 1fr 1fr 0.7fr 0.3fr 0.5fr 0.5fr;
    align-items: center;
    line-height: 1.5em;

    img {
        border-radius: 2px;
    }

    @media (max-width: $collapse) {
        grid-template-columns: 96px 2fr 0.5fr 0.5fr;
        grid-template-rows: auto 1fr;
        justify-content: center;
        align-items: center;
        background: white;
        border: 1px solid #e4e3db;
        box-shadow: 0 2px 4px rgba(28, 28, 28, 0.05);
        border-radius: 4px;
    }

    .nsfwImageContainer div {
        width: 80px;
    }
}

.versionsTextOnlyRow {
    display: grid;
    grid-template-columns: 4.2fr 2fr 1.8fr 0.5fr 0.6fr 0.6fr 0.3fr;
    gap: 5px;
    border-bottom: 1px solid #eee;

    @media (max-width: $collapse) {
        grid-template-columns: 0fr 2fr 0.5fr 0.3fr;
        grid-template-rows: auto 1fr;
        justify-content: center;
        align-items: center;
        background: white;
        border: 1px solid #e4e3db;
        box-shadow: 0 2px 4px rgba(28, 28, 28, 0.05);
        border-radius: 4px;
    }
}

.loadingSpinner {
    filter: grayscale(100%);
    opacity: 0.5;
    pointer-events: none;
    width: 100vw;
}

#versionsTr {
    vertical-align: top;
    border-color: #ccc;
    background: #fbfbf8;
    border-bottom: 1px solid #e4e3db;
    padding-right: 0.8em;

    @media (max-width: $collapse) {
        padding: 0;
    }
}

.versionsTextWithCoversRow {
    display: grid;
    grid-template-columns: 96px 2fr 1fr 1fr 0.7fr 0.3fr 0.6fr 0.3fr;
    align-items: center;
    border-bottom: 1px solid #eee;
    line-height: 1.5em;

    @media (max-width: $collapse) {
        padding: 0;
        grid-template-columns: 0fr 2fr 0.5fr 0.5fr;
        grid-template-rows: auto 1fr;
        justify-content: center;
        align-items: center;
        background: white;
        border: 1px solid #e4e3db;
        box-shadow: 0 2px 4px rgba(28, 28, 28, 0.05);
        border-radius: 4px;
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.mobileStacked {
    display: none;

    @media (max-width: $collapse) {
        grid-row: 1;
        grid-column: 2;
        display: flex;
        flex-direction: column;
        padding: 14px 3.5px;
    }
}
/* stylelint-disable-next-line a11y/no-display-none */
.mobileVersionsContainer {
    display: none;

    @media (max-width: $collapse) {
        display: grid;
        grid-template-columns: 96px 2fr 1fr 1fr 0.7fr 0.3fr 0.6fr 0.3fr;
        height: 100%;
        width: 100%;
    }
}
/* stylelint-disable-next-line a11y/no-display-none */
.mobileStackedVersions {
    display: none;

    @media (max-width: $collapse) {
        grid-row: 1;
        grid-column: 2;
        display: flex;
        flex-direction: column;
        padding: 14px 3.5px;

        width: 220px;
    }
}

#mobileVersionTd {
    padding: 0;
}

.title {
    min-width: 7em;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    font-size: 0.92rem;
    line-height: 1.5rem;

    .button {
        width: 25px;
    }

    /* stylelint-disable-next-line a11y/no-display-none */
    @media (max-width: $collapse) {
        display: none;
    }
}

.discographyLabel {
    min-width: 8em;
    font-size: 0.92rem;
    line-height: 1.5rem;

    /* stylelint-disable-next-line a11y/no-display-none */
    @media (max-width: $collapse) {
        display: none;
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.emptyCell {
    @media (max-width: $collapse) {
        display: none;
    }
}

.cat,
.country {
    min-width: 4em;
    display: flex;
    align-items: center;
    font-size: 0.92rem;
    line-height: 1.5rem;

    /* stylelint-disable-next-line a11y/no-display-none */
    @media (max-width: $collapse) {
        display: none;
    }
}

.year {
    min-width: 3em;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 0.92rem;
    line-height: 1.5rem;

    .unknown {
        white-space: nowrap;
        margin-left: -20px;
    }

    /* stylelint-disable-next-line a11y/no-display-none */
    @media (max-width: $collapse) {
        display: none;
    }
}

.skittles {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 35px;

    @media (max-width: $collapse) {
        grid-row: 1;
        grid-column: 3;
    }
}

.versionsButton {
    max-width: 150px;
    background-color: #f5f4ec;
    margin-top: 8px;
    padding: 4px 8px;
    border: 1px solid #e4e3db;
    border-radius: 2px;

    span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        font-size: 1em;
        line-height: 14px;
        gap: 8px;
    }

    svg {
        min-width: 16px;
        color: #6c6b68;
    }

    @media (max-width: $collapse) {
        max-width: 100%;
        grid-column: 1 / span 4;
        grid-row: 2;
        height: 24px;
        background: #f5f4ec;
        border: none;
        border-top: 1px solid #e4e3db;
        border-radius: 0 0 4px 4px;
        display: flex;
        gap: 7px;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.mobileVersionsButtonClosed {
    display: none;

    @media (max-width: $collapse) {
        max-width: 100%;
        padding: 0 !important;
        grid-column: 1 / span 4;
        grid-row: 2;
        background: #f5f4ec;
        border-top: 1px solid #e4e3db;
        border-radius: 0 0 4px 4px;
        display: flex;
        gap: 7px;
        white-space: nowrap;
        align-items: center;
        justify-content: center;

        button {
            background: #f5f4ec;
            border: none;
            display: flex;
            gap: 7px;
            white-space: nowrap;
            align-items: center;
            justify-content: center;

            svg {
                width: 16px;
            }
        }
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.mobileVersionsButtonOpen {
    display: none;

    @media (max-width: $collapse) {
        max-width: 100%;
        padding: 0 !important;
        grid-column: 1 / span 4;
        grid-row: 2;
        border: none;
        background: black;
        color: #f5f4ec;
        border-top: 1px solid #31312f;
        border-radius: 0;
        display: flex;
        gap: 7px;
        white-space: nowrap;
        align-items: center;
        justify-content: center;

        button {
            border: none;
            background: black;
            color: #f5f4ec;
            display: flex;
            gap: 7px;
            white-space: nowrap;
            align-items: center;
            justify-content: center;

            svg {
                width: 16px;
            }
        }
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.closeVersionsButton {
    display: none;

    @media (max-width: $collapse) {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px !important;
        margin: 0;
        background: black;

        button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px;
            gap: 8px;
            margin: 0;
            width: 100%;
            height: 24px;
            text-decoration: underline;
            background: black;
            color: white;
            border: none;

            svg {
                width: 16px;
            }
        }
    }
}

.actionstd {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.92rem;
    line-height: 1.5rem;

    @media (max-width: $collapse) {
        grid-row: 1;
        grid-column: 4;
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.actionstd ul {
    display: none;

    &[aria-modal='true'] {
        cursor: pointer;
        width: 150px;
        border: 1px solid #e4e3db;
        display: block;
        z-index: 102;
        position: absolute;
        right: 25px;
        top: 20px;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
            background: white;
            padding: 12px 16px;
            white-space: nowrap;
            width: 7.3rem;

            &:hover,
            &:focus {
                background: #f5f4ec;
            }

            a {
                color: black;
            }
        }
    }
}

.actionsDropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;

    &:focus-visible {
        outline: 2px solid #195fcc;
    }

    span {
        display: flex;
        justify-content: flex-end;
        background: transparent;
        border: none;
    }

    text-align: center;

    svg {
        width: 16px;
    }

    button {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        font: inherit;
        color: inherit;
        cursor: pointer;
        outline: none;

        &:focus-visible {
            outline: 2px solid #195fcc;
        }
    }
}

.fallback {
    background: #ddd;
    width: 80px;
    height: 80px;
    color: #999;
    position: relative;
    border-radius: 2px;

    svg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 50px;
        height: 50px;
    }
}

.zoom {
    width: 1rem;
    height: 1rem;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    color: white;
    position: absolute;
    opacity: 0;

    a:focus &,
    a:hover & {
        opacity: 1;
    }
}

.formatsContainer {
    i {
        display: inline;
    }
}
