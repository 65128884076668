/* stylelint-disable no-descending-specificity */
$mobile: 675px;

.releasesPath {
    span {
        display: flex;
        align-items: center;
        margin-top: 24px;
        height: 18px;
    }
    svg {
        padding-left: 10px;
        color: #6c6b68;
        width: 16px;
    }
    h2 {
        font-size: 1.3em;
        line-height: 24px;
        color: #31312f;
    }
    button {
        font-size: 1.3em;
        line-height: 24px;
        color: #31312f;
        display: block;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: bold;
        border: none;
        background: none;
        cursor: pointer;
    }
    span h2:first-child {
        padding-right: 8px;
    }

    .middleChild {
        padding: 0 8px;
    }

    span h2:last-child {
        padding-left: 8px;
    }

    @media (max-width: $mobile) {
        display: none;
    }
}

.filterBarContainerDesktop {
    display: flex;
    margin-top: 8px;

    @media (max-width: 900px) {
        flex-direction: column;

        .two {
            min-width: 100%;
            justify-content: flex-start;
        }
    }
}

.one {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    @media (max-width: $mobile) {
        width: 100%;
        justify-content: space-between;
        gap: 0;
    }
}

.two {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    @media (max-width: $mobile) {
        display: none;
    }
}
/* stylelint-disable-next-line a11y/no-display-none */
.twoMobile {
    display: none;

    @media (max-width: $mobile) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.divider {
    border-right: 1px solid #e5e5e5;
    height: 80%;
}

/* Count */

.countContainer {
    margin-right: 8px;
    white-space: nowrap;
    font-size: 0.89rem;
    line-height: 1.5rem;
}

/* Sort */

.sortContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        border: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }
    svg {
        width: 14px;
    }

    @media (max-width: $mobile) {
        border: 1px solid gray;
        border-radius: 20px;
        font-size: 1em;
        height: 16px;
        svg {
            width: 10.13px;
        }
    }
}

/* views */

.viewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    button {
        padding: 8px;
        width: 36px;
        height: 36px;
        border: 1px solid;
        border-color: var(--grey50);
        border-radius: 36px;
        background: transparent;
        cursor: pointer;
        &[aria-checked='true'] {
            color: #f5f4ec;
            background: #31312f;
            border-radius: 36px;
        }
        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 18px;
            width: 18px;
        }
    }
}

/* Show */

.showContainer {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.selectContainer {
    border: 1px solid #e4e3db;
    border-radius: 2px;
    cursor: pointer;
    padding: 8px;
    select {
        display: flex;
        justify-content: right;
        align-items: center;
        cursor: pointer;
        border: none;
        background: transparent;
        gap: 8px;
    }
}

/* Pagination */

.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    button {
        background: #f5f4ec;
        border: 1px solid #f5f4ec;
        border-radius: 4px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 12px 8px 8px;
        width: 73px;
        height: 34px;
        svg {
            width: 10px;
        }
    }
    button:first-child {
        padding: 8px 8px 8px 12px;
    }

    @media (max-width: $mobile) {
        button {
            svg {
                margin-top: 5px;
                width: 16px;
                margin-bottom: 4px;
            }
        }
    }
}

/* stylelint-disable-next-line a11y/no-display-none */
.paginationContainer .displayButton {
    display: none;
}

/* Mobile Tabs */
/* stylelint-disable-next-line a11y/no-display-none */
.tabsContainerMobile {
    display: none;

    @media (max-width: $mobile) {
        border-bottom: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.innerContainer {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
}

.selection {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 36px;
    background: #f5f4ec;
    border: 1px solid #e4e3db;
    border-radius: 8px 8px 0.5px 0.5px;
    padding: 12px;
    &[aria-checked='true'] {
        color: white;
        background: black;
    }
}
