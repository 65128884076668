.views {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    button {
        padding: 8px;
        width: 36px;
        height: 36px;
        border: 1px solid;
        border-color: #a7a6a0;
        border-radius: 36px;
        background: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;

        &[aria-checked='true'] {
            border-color: #31312f;
            color: #f5f4ec;
            background: #31312f;
            border-radius: 36px;
        }

        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.125rem;
            width: 1.125rem;
        }

        &:focus-visible {
            border: 4px solid #195fcc;
        }
    }
}
