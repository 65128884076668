$collapse: 675px;

.card {
    position: relative;
    width: 150px;
    margin: 0;
}

.title {
    display: block;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 4px;
    letter-spacing: 0.01em;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 14px;
}

.artists {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
}

.year {
    margin-top: 8px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
}

.image {
    display: block;

    img {
        width: 100%;
        background: #e6eaed;
        min-height: 150px;
        min-width: 150px;
        max-height: 150px;
        object-fit: cover;
    }
}

.skittlesOverlay {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    z-index: 1;
}

.skittles {
    white-space: nowrap;
    display: flex;
    gap: 5px;

    @media (max-width: $collapse) {
        grid-row: 1;
        grid-column: 3;
    }
}

.nsfwImageContainer div {
    border: none;
}
